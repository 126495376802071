.table-container {
  padding: 1rem;
  background-color: whitesmoke;
  height: 93vh;
  width: 98vw;
  position: fixed;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rate-input-cell {
    position: relative;
}
.edit-only-column { display: none; }
.tr-in-edit-mode .edit-only-column { display: table-cell; }
.not-editing-row { display: none; }
.rate-hint-popup {
    display: block;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    z-index: 1000;
    width: max-content;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.rate-hint-popup p {
    margin: 0;
    font-size: 12px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  padding: 2rem;
  overflow-y: auto;
}


.modal-content {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  margin: auto;
  max-height: 60%;
  overflow-y: auto;

  h2 {
    margin-top: 0;
    font-size: 1.4rem;
    font-weight: 600;
    color: #1a202c;
    margin-bottom: 1rem;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 0.9rem;
  }

  input[type="text"],
  input[type="number"],
  input[type="password"],
  select,
  textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 0.9rem;
    
    &:focus {
      outline: none;
      border-color: #4299e1;
      box-shadow: 0 0 0 1px #4299e1;
    }
  }

  input[type="checkbox"] {
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
  
  button {
    padding: 0.5rem 1rem;
    background-color: #f8f9fa;
    border: 2px solid #e2e8f0;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    color: black;
    font-size: 0.9rem;

    &:hover {
      background-color: #e2e8f0;
    }

    &[type="submit"] {
      background-color: #4299e1;
      border-color: #4299e1;
      color: white;

      &:hover {
        background-color: #3182ce;
        border-color: #3182ce;
      }
    }
  }
}

.employee-table {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 1rem;
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;

  th, td {
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
  }

  th {
    background-color: #f8f9fa;
    color: black;
    font-weight: bold;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  td {
    background-color: white;
  }

  tbody tr {
    &:hover td {
      background-color: #f7fafc;
    }
  }
}

.edit-button,
.add-employee-button,
.save-button,
.cancel-button {
    padding: 6px 12px;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: auto;
    max-width: 100px;
}

.edit-button {
    background-color: #4CAF50;
    color: white;
}

.save-button {
    background-color: #008CBA;
    color: white;
}

.cancel-button {
    background-color: #f44336;
    color: white;
}

.editing-row {
    background-color: #fff3cd;
}

.hidden-row {
    display: none;
}

/* Keep the header sticky */
.employee-table th {
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Inputs and checkboxes inside the table */
.employee-table input[type="text"],
.employee-table input[type="number"] {
    width: 100%;
    padding: 6px;
    box-sizing: border-box;
}

.employee-table input[type="checkbox"] {
    transform: scale(1.2);
}
