.clockinwindow{
    background-color:whitesmoke;
    position: fixed;
    width:95vw;
    height: 95vh;
    left: 2.5vw;
    top: 2.5vh;
    box-sizing: border-box;
    display:grid;
    overflow-y: scroll;
    cursor:pointer;
    user-select: none;
    box-shadow: 0px 0px 5px black;


    @media (orientation: landscape) {
        grid-template-columns: 1fr 80% 1fr;
        grid-template-rows: 1fr 1fr 80% 1fr;
    };
    @media (orientation: portrait) {
        grid-template-columns: 1fr 10fr 1fr;
        grid-template-rows: 1fr 1fr 5fr 1fr;
   }
}
.clockintitle{
    text-align: center;
    margin-bottom: 1rem;
    @media (orientation: landscape) {
        grid-area:2/1/span 1/span 3;
    };
    @media (orientation: portrait) {
        grid-area:2/2/span 1/span 1;
   }
   h1 {
       font-size: 2.5rem;
       color: black;
       margin: 0.5rem 0;
   }
   h6{
       color:black;
       font-size: 1.2rem;
       margin: 0;
   }
}
.clockinpad{
    @media (orientation: landscape) {
        grid-area:3/2/span 1/span 1;
    };
    @media (orientation: portrait) {
        grid-area:3/2/span 1/span 1;
   }
   .numbutton{
       font-size:7vh !important;
       margin:auto;
       align-items: center;
       justify-content: center;
       height:80%;
   }

    .numpadwrapper{
        width:100%;
        margin: 0 auto;
        height:100%;
        .numpadInnerWrapper{
            height:100%;
        }
        .numbutton{
            width: 20vw;
            height:20vw;
            line-height: calc(min(20vw,15vh));
            max-width: 15vh;
            max-height: 15vh;
            margin-top: 1vh;
            margin-bottom: 1vh;
            border: thin black solid;
            border-radius: 15vh;
            text-align: center;
            color:black;
        }
    }
}
.clock-entries {
    width: 100%;
    max-width: 600px;
    margin: 1rem auto;
    text-align: center;
    
    h2 {
        font-size: 2.5rem;
        font-weight: 700;
        color: #1a202c;
        margin-bottom: 0.5rem;
    }

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-bottom: 2rem;
        border-radius: 12px;
        overflow: hidden;

        th, td {
            padding: 1rem;
            text-align: center;
            border-bottom: 1px solid #e2e8f0;
        }

        th {
            background-color: #f8f9fa;
            color: black;
            font-weight: bold;
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }

        td {
            background-color: white;
            font-size: 1.1rem;
        }

        tr:last-child td {
            border-bottom: none;
        }

        .active-clock-in td {
            background-color: #fef3c7;
            color: #92400e;
            font-weight: 500;
        }
    }
}
.clockin-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;

    .action-buttons {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        max-width: 500px;
        margin: 2rem auto;
        padding: 1rem;

        button {
            width: 100%;
            padding: 1.25rem;
            border: 2px solid transparent;
            border-radius: 12px;
            font-size: 2rem;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            cursor: pointer;
            transition: all 0.2s ease;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

            &:active {
                transform: translateY(2px);
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
        }

        .clock-button {
            height: 140px;
            background-color: #f8f9fa;
            color: black;
            border-color: #e2e8f0;
            margin-bottom: 1rem;

            &.clock-in {
                background-color: #fef3c7;
                border-color: #fef3c7;
                &:hover {
                    background-color: #fef3c7;
                }
            }
            
            &.clock-out {
                background-color: #fef3c7;
                border-color: #fef3c7;
                &:hover {
                    background-color: #fef3c7;
                }
            }

            &:hover {
                box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
            }
        }

        .cancel-button {
            height: 80px;
            background-color: #f8f9fa;
            color: black;
            border-color: #e2e8f0;
            font-size: 1.5rem;

            &:hover {
                background-color: #f8f9fa;
                box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.clockinwindow {
    .action-buttons {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        max-width: 500px;
        margin: 2rem auto;
        padding: 1rem;

        button {
            width: 100%;
            height:40px;
            border: 2px solid transparent;
            border-radius: 12px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            cursor: pointer;
            transition: all 0.2s ease;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

            &:active {
                transform: translateY(2px);
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
        }

        .clock-button {

            background: linear-gradient(145deg, #2d3748, #1a202c);
            color: white;
            border-color: #4a5568;

            &.clock-in {
                background: linear-gradient(145deg, #1e4620, #145214);
                border-color: #2f855a;
                &:hover {
                    background: linear-gradient(145deg, #145214, #1e4620);
                }
            }
            
            &.clock-out {
                background: linear-gradient(145deg, #9b2c2c, #742a2a);
                border-color: #c53030;
                &:hover {
                    background: linear-gradient(145deg, #742a2a, #9b2c2c);
                }
            }

            &:hover {
                box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
            }
        }

        .cancel-button {
            background: linear-gradient(145deg, #2d3748, #1a202c);
            color: white;
            border-color: #4a5568;

            &:hover {
                background: linear-gradient(145deg, #1a202c, #2d3748);
                box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

h3 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #2d3748;
}

h4 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #4a5568;
    margin-bottom: 2rem;
}
