.table-container {
    padding: 20px;
}

.filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;

    .filter-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        label {
            font-weight: bold;
            margin-bottom: 5px;
        }

        input[type="text"],
        select {
            padding: 5px;
            font-size: 14px;
        }
    }

    /* Adjust for the checkbox label */
    .filter-item label {
        display: flex;
        align-items: center;

        input[type="checkbox"] {
            margin-right: 5px;
        }
    }
}


.giftcard-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        border: 1px solid #ddd;
        padding: 8px;
        vertical-align: top;
    }

    th {
        background-color: #f2f2f2;
        text-align: left;
    }

    tr:hover {
        background-color: #f1f1f1;
    }

    .editing-row {
        background-color: #e9f5ff;
    }

    .edit-button,
    .delete-button,
    .save-button,
    .cancel-button {
        margin-right: 5px;
    }
}


.add-giftcard-form {
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ddd;

    h2 {
        margin-top: 0;
    }

    .form-group {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        label {
            width: 150px;
            font-weight: bold;
        }

        input[type='text'],
        input[type='number'],
        input[type='date'] {
            flex: 1;
            padding: 5px;
        }

        input[type='checkbox'] {
            margin-left: 0;
        }

        textarea {
            flex: 1;
            padding: 5px;
            resize: vertical;
            min-height: 80px;
        }
    }

    .add-button {
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
    }
}