.orderGridWrapper {

	.notes{
		//italic
		font-style: italic;
		text-align: left;
		//take up two grid columns
		grid-column: 1 / span 2;
	}

	.context-menu {
		background-color: white;
		border: 1px solid #ccc;
		z-index: 1000;
	}

	.context-menu-list {
		list-style: none;
		padding: 5px 0;
		margin: 0;
	}

	.context-menu-item {
		padding: 8px 16px;
		cursor: pointer;
	}

	.context-menu-item:hover {
		background-color: #f0f0f0;
	}




	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 8fr 1fr;
	height: 100%;
	margin: 1% 1% 1% 1%;

	.refunded {
		//strikethrough
		text-decoration: line-through;
	}

	.orderGrid {
		display: grid;
		overflow-y: scroll;
		grid-template-columns: 3fr 1fr;
		grid-template-rows: repeat(100, auto);
		align-content: bottom;
	}

	.orderGrid::-webkit-scrollbar {
		display: none;
	}

	.navarrows {
		font-size: .6rem;
		color: grey;
	}

	.prev {
		margin-right: 10%;
	}

	.totalGrid {
		display: grid;
		grid-template-columns: 3fr 1fr;
		grid-template-rows: repeat(3, auto);
		align-content: bottom;
	}

	.orderName {
		grid-column: span 2;
		text-align: left;
		font-size: min(5vw, 2vh, 30px);
		margin: 0;

		sup {
			font-size: 0.5rem;
		}
	}

	.listItem,
	.modifier {
		display: grid;
		grid-template-columns: 6fr 1fr;
		text-align: left;
		font-size: min(5vw, 2vh, 30px);
		margin: 0;

		sup {
			font-size: 0.5rem;
		}
	}

	.listItemPrice {
		text-align: right;
		font-size: 1vw;
		font-size: min(5vw, 2vh, 30px);
		margin: 0;
	}

	.redPayment {
		color: red;
	}

	.clearorder {
		text-align: center;
	}

	.modifier {
		padding-left: 2vw;
		//color: grey
	}

	.input-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0;
		grid-column: span 2;
		box-sizing: border-box;
		/* New */
	}

	.attribute-input-key,
	.attribute-input-value {
		flex: 1;
		padding: 3px;
		box-sizing: border-box;
		/* New */
		width: 40%;
		/* New */
	}

	.save-button {
		flex: 1;
		box-sizing: border-box;
		/* New */
		padding: 3px;
		width: 20%;
		/* New */
	}
}