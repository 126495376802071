/* Flash effect */
.flash {
    animation: flash-animation 0.2s;
}

@keyframes flash-animation {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* Countdown timer */
.countdown {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10vw;
    color: white;
    text-shadow: 2px 2px 4px #000;
    z-index: 9999;
}

/* Upload messages */
.uploading-message,
.upload-success,
.upload-error {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2vw;
    color: white;
    text-shadow: 1px 1px 2px #000;
}

/* Hide video stream when loading */
.video-stream.hidden {
    display: none;
}

/* Camera controls */
.camera-controls {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 9999;
}

#photo-capture-button,
#close-camera-button {
    margin: 10px;
    //padding: 10px;
    font-size: 1vw;
    cursor: pointer;
}

#photo-capture-button{
    background-color: #4CAF50;
    /* Green */
    color: white;
    border: none;
    border-radius: 10px;
}

#close-camera-button {
    background-color: #f44336;
    /* Red */
    color: white;
    border: none;
    border-radius: 10px;
}

/* Adjust modal styling to ensure proper positioning */
.photo-upload-form.modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent backdrop */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
    /* Ensure it's above other elements */
}

/* Ensure video stream covers the modal */
.camera-modal {
    //position: relative;
    width: 100%;
    max-width: 480px;
    max-height: 480px;
    background: black;
    display: block;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}

.video-stream {
    width: 100%;
    max-width: 480px;
    height: auto;
    border-radius: 25px;
}

/* Adjust loading box */
.loading-box {
    //position: relative;
    width: 100%;
    max-width: 480px;
    max-height: 480px;
    background: black;
    display: block;
    justify-content: center;
    align-items: center;
}