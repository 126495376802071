.fulOptionsModal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	max-width: 500px;
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	z-index: 10000;
}

.fulOptionsModal label {
	display: block;
	margin-top: 10px;
	font-size: 1em;
}

.fulOptionsModal input[type="number"] {
	//width: 100px;
	padding: 10px;
	margin-top: 5px;
	font-size: 1em;
	border: 1px solid #ddd;
	border-radius: 5px;
}

.fulOptionsModal input[type="checkbox"] {
	margin-top: 10px;
}

.fulOptionsModal button {
	margin-top: 20px;
	padding: 10px;
	width: 100%;
	font-size: 1em;
	border: 1px solid #ddd;
	border-radius: 5px;
	background-color: black;
	color: white;
	cursor: pointer;
}

.fulOptionsModal button:hover {
	background-color: #e9e9e9;
	color: black;
}

.paymentButtonsInnerWrapper {

	@media (orientation: landscape) {
		grid-area: 3/2 / span 1 / span 1;
		height: 98%;
		width: 98%;
		margin: auto;

		.payBtnHalf {
			height: calc((98%)/3);
			width: 50%;
			font-size: min(1.5vw, 2.5vh);
		}

		.payBtn {
			height: calc((98%)/3);
			width: 100%;
			font-size: min(1.5vw, 2.5vh);
		}

		.payBtnAlt {
			height: calc((100% - 1vh)/3);
			width: 100%;
			font-size: min(1.5vw, 2.5vh);
		}

		.fulBtn {
			height: calc(100% - 1vh);
			width: 40%;
			margin: 0.5vh 5%;
			font-size: min(1.5vw, 2.5vh);
		}
	}

	@media (orientation: portrait) {
		grid-area: 5/1 / span 1 / span 2;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 0.5vh;
		padding: 0.5vh;
		box-sizing: border-box;

		.payBtnHalf, .payBtn, .payBtnAlt, .fulBtn {
			height: 100%;
			flex: 1;
			font-size: clamp(8px, 1.5vh, 20px);
			margin: 0;
		}
	}

	// Common button styles
	.payBtnHalf, .payBtn, .payBtnAlt, .fulBtn {
		padding: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		border: #ddd 1px solid;
		touch-action: manipulation;
		-webkit-tap-highlight-color: transparent;
		user-select: none;
		
		&:active {
			background-color: rgba(0, 0, 0, 0.1);
		}
	}

	.paymentModalBackdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, 0.5);  /* semi-transparent black */
        z-index: 9999;  /* Ensure it's on top of other content */
    }

	.paymentModal {
		.orderNotes{
			color:black;
			font-size: inherit;
			outline: none; /* Remove the outline when the input is focused */
			transition: border-color 0.3s; /* Transition for a smooth effect */
			font-size: 2vw;
			position: relative;
			z-index: 1;
			padding: 0 0 .5vw 0;
			margin: 0 0 2vw 0;
			//black border
			border: 1px solid black;
			border-radius: 5px;
			background-color: white;
			box-sizing: border-box;
			width: 100%;
		}

		#cancel-amount{
			color:black;
			text-align: center;
			font-size: 2em;
		}

		#cancel-reason{
			color:black;
			text-align: center;
			font-size: 1em;
			//black border
			border: 1px solid black;
			border-radius: 5px;
			background-color: white;
			box-sizing: border-box;
			width: 100%;

		}

        .redPayment{
            color:red;
        }

        .transactionRow td {
            position: relative; /* Set to relative to position the button inside it */
            padding: 5px; /* Some padding for better appearance */
        }
        
        .deleteTransactionButton {
            color:black;
            background-color: transparent;
            border: none;
            width: 12px;
            height: 14px;
            padding:0;
            font-size: 12px;
            cursor: pointer;
            position: absolute; /* Absolutely position the button inside the table cell */
            top: 5px; /* Distance from the top of the cell */
            right: 5px; /* Distance from the right of the cell */
        }

        .receiptTable {
            width: 100%;
            border-collapse: collapse;
            text-align:left;
        }
        
        .receiptTable td {
            border: 1px solid #ddd;
            padding: 8px;
        }
        
        .receiptTable tr:nth-child(even) {
            background-color: #f2f2f2;
        }
        

		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		max-width: 500px;
		background-color: white;
		padding: 20px;
		border-radius: 8px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		z-index: 10000;

		input {
			height: 35%;
			width: 100%;
			padding: 0;
			margin: 0;
			background: none; /* Remove the default background */
			border: none; /* Remove the default border */
			//border-bottom: 2px solid #333; /* Add a bottom border */
			outline: none; /* Remove the outline when the input is focused */
			transition: border-color 0.3s; /* Transition for a smooth effect */
			font-size: 5vw;
			position: relative;
			z-index: 1;
			color: transparent;
		}

		.inputWrapper {
			position: relative;
            margin-top:2vh;
			input{
				text-align: center;
			}
		}

		input:focus {
			border-color: green; /* Change the bottom border color when the input is focused */
		}

		.displayedValue {
			position: absolute;
			height: 35%;
			width: 100%;
            font-size: 5vw;
			text-align: center;
			padding: 0;
			margin: 0;
			transform: translateY(-50%);
			z-index: 2;
			pointer-events: none; /* Ensures the input beneath is clickable */
			color: black;
		}
	}

	.paymentNums {
		height: 30vh;
		width: 30vw;
	}

	.closePayment {
		width: 10vw;
		float: right;
        margin-bottom:2vh;
	}
	/* Black backdrop for the discount modal */
	.discountModalBackdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		backdrop-filter: blur(5px);
		background-color: rgba(0, 0, 0, 0.5);  /* semi-transparent black */
		z-index: 10001;  /* Ensure it's below the discount modal but above other content */
	}

	/* Styles for the discount modal */
	.editDiscount {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		max-width: 500px;
		background-color: white;
		padding: 20px;
		border-radius: 8px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		z-index: 10002; /* Ensure it's above other modals */

		button {
			margin-top: 10px;
			padding: 10px;
			width: 100%;
			font-size: 1em;
			border: 1px solid #ddd;
			border-radius: 5px;
			background-color: black;
			cursor: pointer;
		}

		button:hover {
			background-color: #e9e9e9;
			color:black;
		}

		label {
			display: block;
			margin-top: 10px;
			font-size: 1em;
		}

		input[type="number"] {
			width: calc(100% - 22px);
			padding: 10px;
			margin-top: 5px;
			font-size: 1em;
			border: 1px solid #ddd;
			border-radius: 5px;
		}

		sup {
			cursor: pointer;
			font-size: 1.2em;
			float: right;
		}
	}

}
