.table-container {
    padding: 20px;
  }
  
  .date-filter {
    margin-bottom: 20px;
  
    label {
      margin-right: 10px;
      font-weight: bold;
    }
  
    input[type='date'] {
      padding: 5px;
      font-size: 14px;
    }
  }
  
  .visits-table {
    width: 100%;
    border-collapse: collapse;
  
    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
      vertical-align: top;
    }
  
    th {
      background-color: #f2f2f2;
      text-align: left;
    }
  
    tr:hover {
      background-color: #f1f1f1;
    }
  
    button {
      padding: 5px 10px;
      background-color: #e74c3c;
      color: #fff;
      border: none;
      cursor: pointer;
      font-size: 14px;
      border-radius: 4px;
    }
  
    button:hover {
      background-color: #c0392b;
    }
  }
  
  