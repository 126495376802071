.partyInfoList{
    text-align: left;
    padding: 0 2em;
    margin: .2em;
    font-size:.7em;
}
.partyButton{
    width:50%;
    margin-top:5em;
    margin: 2em auto 0 auto;
}
.partyTitle{
    margin: 1em auto;
}

.giantX{
    font-size:10em;
}