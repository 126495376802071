.numpad{
    display:grid;
    width: 100%;
    height:100%;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 25% 25% 25% 25%;
    
}
.numbutton{
    align-items: center;
    justify-content: center;
    margin-top:0
}
.inputtext{
    width:53%;
    height:10%;
    padding: 0 5% 0 5%;
}
.clearvalue{
    width: 14%;
    height:10%;
    margin-left:1%;
}