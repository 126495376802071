.progress-message {
    margin-bottom: 1em;
    font-weight: bold;
    color: #333;
  }
  
.table-container {
    padding: 20px;
  }
  
  .filter-container {
    margin-bottom: 20px;
  
    .filter-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
  
      label {
        font-weight: bold;
        margin-bottom: 5px;
      }
  
      input[type='text'] {
        padding: 5px;
        font-size: 14px;
      }
    }
  }
  
  .members-table {
    width: 100%;
    border-collapse: collapse;
  
    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
      vertical-align: top;
    }
  
    th {
      background-color: #f2f2f2;
      text-align: left;
    }
  
    tr.editing-row {
      background-color: #ffffcc;
    }
  
    tr:hover {
      background-color: #f1f1f1;
    }
  
    input[type='text'],
    input[type='number'],
    input[type='date'],
    input[type='email'] {
      width: 100%;
      box-sizing: border-box;
      padding: 5px;
      font-size: 14px;
    }
  
    .edit-button,
    .save-button,
    .cancel-button {
      padding: 5px 10px;
      font-size: 14px;
      cursor: pointer;
    }


    .refresh-button {
        background-color: #3498db;
        color: #fff;
        border: none;
        margin-left: 5px;
        padding: 5px 10px;
        cursor: pointer;
      }
      
      .refresh-button:hover {
        background-color: #2980b9;
      }
      
      .changed-field {
        background-color: #ffeb3b; /* Light yellow background */
      }
      
      .editing-row input.changed-field {
        background-color: #ffeb3b;
      }
      
  
    .save-button {
      background-color: #2ecc71;
      color: #fff;
      border: none;
      margin-right: 5px;
    }
  
    .cancel-button {
      background-color: #e74c3c;
      color: #fff;
      border: none;
    }
  }
  

